<template>
  <v-card
    height="100%"
    class="mx-auto"
    elevation="0"
    color="primary"
    max-width="500"
    tile
  >
    <v-btn class="mt-2 transparent" color="#fff" elevation="0"
      ><v-icon x-large @click="close()">mdi-close</v-icon></v-btn
    >
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :disabled="item.disabled"
          class="item"
          @click="handleClick(item)"
        >
          <v-list-item-content>
            <v-list-item-title
              class="fp"
              v-text="item.label"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isTokenValid" @click="$store.dispatch('purgeAll')">
          <v-list-item-content>
            <v-list-item-title class="fp"
              >KIJELENTKEZÉS <v-icon color="white">mdi-exit-to-app</v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import eventBus from "../eventBus.js";
export default {
  data() {
    return {
      items: [],
      active_tab: 0,
      isManager: true,
      defaultItems: [
        {
          label: "Bejelentkezés",
          slug: "login",
          disabled: false,
          action: null,
          id: 0,
        },
      ],
      loggedInItems: [
        {
          label: "FELTÖLTÉSEK",
          slug: "feltoltesek",
          disabled: false,
          id: 1,
        },
        {
          label: "PONT BEVÁLTÁS",
          slug: "pont-bevaltas",
          disabled: true,
          id: 2,
        },
        {
          label: "KORÁBBI BEVÁLTÁSOK",
          slug: "korabbi-bevaltasok",
          disabled: true,
          id: 3,
        },
        {
          label: "ÉRTESÍTÉSEK",
          slug: "ertesitesek",
          disabled: false,
          id: 4,
        },
        {
          label: "RANGSOR",
          slug: "rangsor",
          disabled: false,
          id: 5,
        },
        {
          label: "HÍREK",
          slug: "hirek",
          disabled: false,
          id: 6,
        },
        {
          label: "BEÁLLÍTÁSOK",
          slug: "beallitasok",
          disabled: false,
          id: 7,
        },
      ],
    };
  },
  mounted() {
    // YIELD BY LOGGED IN STATE
    // this.items = Array.from(this.defaultItems);
    this.loggedInItems;
    this.updateNavItems();
    eventBus.$on("AFTER_NAVIGATION", () => {
      this.updateNavItems();
    });
  },
  computed: {
    isTokenValid() {
      return this.$store.getters.isTokenValid;
    },
  },
  methods: {
    navigateTo(slug) {
      if (this.$route.name !== slug) {
        this.$router.push(slug);

        eventBus.$emit("AFTER_NAVIGATION");
      }
    },
    handleClick(item) {
      if (item.action) {
        this.$store.dispatch(item.action);
      } else {
        this.navigateTo(item.slug);
      }
      this.close();
    },

    close() {
      eventBus.$emit("DRAWER_CLOSE");
    },
    updateNavItems() {
      if (!this.$store.getters.isTokenValid) {
        this.items = this.defaultItems;
      } else {
        if (this.$store.getters.isManager) {
          this.loggedInItems.forEach((item, index) => {
            if (item.id === 2 || item.id === 3)
              this.loggedInItems[index].disabled = false;
          });
        }
        this.items = this.loggedInItems;
      }
    },
  },
};
</script>
<style scoped>
.fp {
  text-align: center;
  font-weight: 400;
  color: #fff;
}
.v-list-item--disabled .fp {
  opacity: 0.5;
}

.transparent {
  background-color: transparent;
  color: #fff;
}
</style>
