export function toQueryString(obj) {
  return Object.keys(obj)
    .map(function (key) {
      if (typeof obj[key] === "object") {
        return obj[key]
          .map(function (value) {
            return key + "[]=" + value;
          })
          .join("&");
      }
      return key + "=" + obj[key];
    })
    .join("&");
}

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function getRandomId() {
  return Math.random().toString(36).substring(2, 25);
}

export function getLoggedInHeader(token) {
  return {
    Authorization: `${token}`,
    "Content-Type": "application/json",

    // "Access-Control-Allow-Methods": "*"
  };
}
export function getMultipartHeader(token) {
  return {
    Authorization: `${token}`,
  };
}

export const fv = {
  email: [
    (v) =>
      !v ||
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      "Nem érvényes",
  ],
  default: [(v) => !!v || "Kötelező"],
  pw: [(v) => (!!v && v.length > 8) || "Minimum 6 karakter"],
};
