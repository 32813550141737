<template>
  <v-app id="main">
    <!-- <Debug></Debug> -->

    <v-navigation-drawer width="100%" v-model="drawer" app>
      <Navigation></Navigation
    ></v-navigation-drawer>
    <v-progress-linear
      class="abs"
      indeterminate
      color="primary"
      v-if="isLoading"
    ></v-progress-linear>
    <v-app-bar app dark elevation="0" color="secondary">
      <v-app-bar-nav-icon
        v-if="!isBack"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-btn icon v-if="isBack" @click="$router.go(-1)">
        <v-icon>mdi-cart-outline</v-icon>
      </v-btn>
      <img class="logo" v-if="isLogo" src="./assets/hell_app_logo.png" alt="" />
      <v-toolbar-title class="pr-2 fp">{{ $t(activeTitle) }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        v-if="isCart"
        :disabled="!cartHasItems"
        @click="$router.push('/kosar')"
      >
        <v-icon>mdi-cart-outline</v-icon>
      </v-btn>
      <v-btn icon v-if="isFilter" @click="toFilter()">
        <v-icon>mdi-tune-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <MessageBar></MessageBar>
  </v-app>
</template>

<script>
import MessageBar from "./components/MessageBar.vue";
import Navigation from "./components/Navigation.vue";
// import Debug from "./components/Debug.vue";
import eventBus from "./eventBus.js";

export default {
  components: {
    MessageBar,
    Navigation,
    // Debug,
  },
  name: "App",
  data: () => ({
    drawer: false,
    isLoading: false,
    isCart: false,
    isFilter: false,
    updateExists: false,
    registration: null,
    refreshing: false,
  }),
  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
    eventBus.$on("LOAD_ON", () => {
      // console.log("load");
      this.isLoading = true;
    });
    eventBus.$on("DRAWER_CLOSE", () => {
      // console.log("load");
      this.drawer = false;
    });
    eventBus.$on("LOAD_OFF", () => {
      this.isLoading = false;
      //
    });
    eventBus.$on("RIGHT_NAV", (state) => {
      if (state === "CART") {
        this.isCart = true;
      } else if (state === "FILTER") {
        this.isFilter = true;
      } else if (state === "DEFAULT") {
        this.isCart = false;
        this.isFilter = false;
      }
      //
    });
  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshPWA() {
      this.updateExists = false;
      if (this.registration || this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    handleLogout() {
      this.$store.dispatch("purgeAll");
    },
    toFilter() {
      eventBus.$emit("SHOW_FILTER");
    },
  },
  computed: {
    isVisible() {
      return this.$store.getters.isTokenValid;
    },
    activeTitle() {
      return this.$store.getters.activeTitle;
    },
    isLogo() {
      return this.$store.getters.isLogo;
    },
    cartHasItems() {
      return this.$store.getters.getCart.length > 0;
    },
    isBack() {
      return this.$router.currentRoute.name === "kosar";
    },
  },
  watch: {},
};
</script>

<style scoped>
.abs {
  position: absolute;
  top: 0;
  z-index: 100;
}
.v-toolbar__title {
  font-size: 14px;
  /*padding-right: 12px;*/
  /*letter-spacing: 2px;*/
  /*  color: var(--red);
  font-weight: 700;
  padding-left: 20px;
  margin-left: 20px;
  min-width: 100px;
  border-left: 3px solid;*/
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
.logo {
  width: 60px;
}
</style>
<style lang="scss">
@import "./assets/css/stylesheet.css";
$font-family: "Rubik";
.v-application {
  [class*="text-"] {
    color: #36405a;
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}
.to-top-right {
  position: absolute;
  right: 0;
  top: 0;
}
.offset-50 {
  transform: translate(50%, -50%);
}
* {
  font-family: Rubik;
}
.fp,
.fp * {
  font-family: "FalconPunch";
}
.container {
  max-width: 1200px !important;
}
body {
  background-color: #000;
}
.v-main__wrap {
  background-color: #000;
}
.bigDigit {
  font-size: 300%;
  display: block;
  font-weight: 100;
}
.spacer {
  padding: 1rem;
}
.bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:root {
  --red: #ed1c24;
  --secondary: #000;
}
.content {
  margin-top: 4rem;
}
.rscope {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
