import Vue from "vue";
import VueRouter from "vue-router";
import moment from "moment";
import eventBus from "../eventBus.js";
import store from "../store/index.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/hirek",
    name: "hirek",
    component: () => import(/* webpackChunkName: "news" */ "../views/News.vue"),
  },
  {
    path: "/pont-bevaltas",
    name: "pont-bevaltas",
    component: () =>
      import(/* webpackChunkName: "shop" */ "../views/Products.vue"),
  },
  {
    path: "/korabbi-bevaltasok",
    name: "korabbi-bevaltasok",
    component: () =>
      import(
        /* webpackChunkName: "shopHistory" */ "../views/PurchaseHistory.vue"
      ),
  },
  {
    path: "/ertesitesek",
    name: "ertesitesek",
    component: () =>
      import(/* webpackChunkName: "messages" */ "../views/PushHistory.vue"),
  },
  {
    path: "/rangsor",
    name: "rangsor",
    component: () =>
      import(/* webpackChunkName: "ranking" */ "../views/Rankings.vue"),
  },
  {
    path: "/kosar",
    name: "kosar",
    component: () => import(/* webpackChunkName: "cart" */ "../views/Cart.vue"),
  },
  {
    path: "/feltoltesek",
    name: "feltoltesek",
    component: () =>
      import(/* webpackChunkName: "upload" */ "../views/Uploads.vue"),
  },
  {
    path: "/feltoltes",
    name: "feltoltes",
    component: () =>
      import(/* webpackChunkName: "uploads" */ "../views/Upload.vue"),
  },
  {
    path: "/beallitasok",
    name: "beallitasok",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
  },
  {
    path: "/huto-beallitasok",
    name: "huto-beallitasok",
    component: () =>
      import(
        /* webpackChunkName: "fridgeSettings" */ "../views/FridgeSetup.vue"
      ),
  },
  // {
  //   path: "/termekek",
  //   name: "termekek",
  //   component: () =>
  //     import(/* webpackChunkName: "termekek" */ "../views/Products.vue"),
  // },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      conditionalRoute: true,
    },
  },
];
function isTokenValid() {
  const token = localStorage.getItem("token");
  if (token) {
    const decoded = Vue.$jwt.decode(token);
    return moment.unix(decoded.exp).isAfter(moment());
  }
  return false;
}
const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  console.log(store.getters.isTokenValid);
  if (to.name !== "login" && !isTokenValid()) next({ name: "login" });
  else next();
});
router.afterEach((to) => {
  if (to.name !== "login" || to.name !== "home") {
    localStorage.setItem("LS_ROUTE_KEY", `\\${to.name}`);
  }
  store.dispatch("updateTitle", `navigation.${to.name}`);
  eventBus.$emit("AFTER_NAVIGATION");
});
export default router;
