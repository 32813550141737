import Vue from "vue";
import moment from "moment";
import Vuex from "vuex";
import VueJWT from "vuejs-jwt";
import eventBus from "../eventBus.js";
import {
  LOGIN_ENDPOINT,
  API_BASE,
  NEWS_ENDPOINT,
  // DEFAULT_HEADER,
  // JWT_DEFAULTS,
  // REPISTORY_ENDPOINT,
} from "../util/constants.js";
import createPersistedState from "vuex-persistedstate";
import {
  toQueryString,
  getLoggedInHeader,
  getMultipartHeader,
} from "../util/functions";

Vue.use(Vuex);
Vue.use(VueJWT, {
  keyName: "token",
  storage: "localStorage",
});

export default new Vuex.Store({
  state: {
    auth: {
      isLoggedIn: false,
      token: null,
      user: null,
    },
    app: {
      activeTitle: "app.title",
      uploadLockUntil: null,
      store: null,
    },
    cart: {
      items: [],
    },
  },
  getters: {
    isTokenValid() {
      const token = localStorage.getItem("token");
      if (token) {
        const decoded = Vue.$jwt.decode(token);

        return moment.unix(decoded.exp).isAfter(moment());
      }
      return false;
    },
    activeTitle(state) {
      return state.app.activeTitle;
    },
    isLogo(state) {
      return state.app.isLogo;
    },
    isOwner(state) {
      return state.auth.user.role === "ROLE_CHIEF";
    },
    getUser(state) {
      return state.auth.user;
    },
    getStore(state) {
      return state.app.store;
    },
    getCart(state) {
      return state.cart.items;
    },
    totalPoints(state) {
      return state.app.store.currentPoints;
    },
    isManager(state) {
      return state.auth.user.roles.includes("ROLE_CHIEF");
    },
  },
  mutations: {
    UPDATE_TITLE: (state, title) => {
      state.app.activeTitle = title;
    },
    REMOVE_CART_ITEM: (state, id) => {
      state.cart.items = state.cart.items.filter(
        (cartitem) => cartitem.id !== id
      );
    },
    SET_LOGO: (state, val) => {
      state.app.isLogo = val;
    },
    SAVE_USER: (state, user) => {
      state.auth.user = user;
    },
    ADD_TO_CART: (state, item) => {
      state.cart.items.push(item);
    },
    LOG_IN: (state, token) => {
      localStorage.setItem("token", token);
      state.auth.isLoggedIn = true;
      state.auth.token = token;
      localStorage.setItem("hadLastRoute", "true");
      eventBus.$emit("AFTER_NAVIGATION");
    },
    LOG_OUT: (state) => {
      state.auth.isLoggedIn = false;
      state.auth.token = "";
      localStorage.removeItem("token");
      // localStorage.removeItem("hadLastRoute");
      // localStorage.removeItem("vuex");
    },
    SAVE_STORE(state, store) {
      state.app.store = store.store;
    },
    INCREASE_COUNT(state, id) {
      const target = state.cart.items.find((item) => item.id === id);
      if (target) Vue.set(target, "count", (target.count += 1));
    },
    DECREASE_COUNT(state, id) {
      const target = state.cart.items.find((item) => item.id === id);
      if (target && target.count > 1) {
        Vue.set(target, "count", (target.count -= 1));
      }
    },
    EMPTY_CART(state) {
      state.cart.items = [];
    },
  },
  actions: {
    increaseCount({ commit }, id) {
      commit("INCREASE_COUNT", id);
    },
    decreaseCount({ commit }, id) {
      commit("DECREASE_COUNT", id);
    },
    emptyCart({ commit }) {
      commit("EMPTY_CART");
    },
    addToCart({ commit, state }, item) {
      let alreadyAdded = state.cart.items.some(
        (cartitem) => cartitem.id === item.id
      );
      if (!alreadyAdded) {
        item.count = 1;
        commit("ADD_TO_CART", item);
        return true;
      }
      return false;
    },
    removeCartItem({ commit }, id) {
      commit("REMOVE_CART_ITEM", id);
    },
    saveUser({ commit }, user) {
      commit("SAVE_USER", user);
    },
    updateTitle({ commit }, title) {
      commit("UPDATE_TITLE", title);
    },
    setLogo({ commit }, val) {
      commit("SET_LOGO", val);
    },
    setUploadLock({ commit }, val) {
      localStorage.setItem("lockedUntil", val);
    },
    async logIn({ commit }, user) {
      let url = `${API_BASE}${LOGIN_ENDPOINT}`;
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        });
        if (response.ok) {
          const jsonResponse = await response.json();
          console.log(jsonResponse);
          commit("SAVE_USER", jsonResponse);
          commit("LOG_IN", jsonResponse.jwtToken);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async postData({ commit }, data) {
      let query = toQueryString({ title: data.title, html: data.html });
      let url = `${API_BASE}${NEWS_ENDPOINT}?${query}`;
      let formData = new FormData();
      formData.append("newsImages", data.newsImages);
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: getLoggedInHeader(localStorage.getItem("token")),
          body: formData,
        });
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async getCollection({ commit }, { end, payload }) {
      let url = `${API_BASE}${end}`;
      eventBus.$emit("LOAD_ON");
      const header = getLoggedInHeader(localStorage.getItem("token"));
      const query = {
        method: "GET",
        headers: header,
      };
      if (payload) {
        query.data = JSON.tringify(payload);
      }
      try {
        let response = await fetch(url, query);
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async getStore({ commit }) {
      let end = "client/users/options";
      let url = `${API_BASE}${end}`;
      const header = getLoggedInHeader(localStorage.getItem("token"));
      const query = {
        method: "GET",
        headers: header,
      };
      try {
        let response = await fetch(url, query);
        if (response.ok) {
          const jsonResponse = await response.json();
          commit("SAVE_STORE", jsonResponse);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async getPatchCollection({ commit }, { end, payload }) {
      let url = `${API_BASE}${end}`;
      eventBus.$emit("LOAD_ON");
      const header = getLoggedInHeader(localStorage.getItem("token"));
      const query = {
        method: "PATCH",
        headers: header,
        data: JSON.stringify(payload),
      };
      console.log(payload);

      try {
        let response = await fetch(url, query);
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async removeItem({ commit }, { end }) {
      let url = `${API_BASE}${end}`;
      const header = getLoggedInHeader(localStorage.getItem("token"));
      eventBus.$emit("LOAD_ON");
      try {
        let response = await fetch(url, {
          method: "DELETE",
          headers: header,
        });
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse || true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async putPath({ commit }, { end, data }) {
      let url = `${API_BASE}${end}`;
      let header = getLoggedInHeader(localStorage.getItem("token"));
      eventBus.$emit("LOAD_ON");

      let query = {
        method: "PUT",
        headers: header,
        redirect: "follow",
      };
      console.log(data);
      if (data) {
        query.data = JSON.stringify(data);
      }
      try {
        let response = await fetch(url, query);
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse || true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async addNewItem({ commit }, { end, data }) {
      let url = `${API_BASE}${end}`;
      const header = getLoggedInHeader(localStorage.getItem("token"));
      if (end === "admin/admin-users") {
        header["Redirect-Uri"] = `${window.location.origin}/#/login`;
      }
      var form_data = new FormData();

      for (var key in data) {
        form_data.append(key, data[key]);
      }
      console.log(data);
      eventBus.$emit("LOAD_ON");
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: header,
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const jsonResponse = await response.text();
          return response || true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async importFromFile({ commit }, { end, data }) {
      let url = `${API_BASE}${end}`;
      const header = getLoggedInHeader(localStorage.getItem("token"));
      var formdata = new FormData();
      formdata.append("stores", data[0]);

      var requestOptions = {
        method: "POST",
        headers: getMultipartHeader(localStorage.getItem("token")),
        body: formdata,
        redirect: "follow",
      };

      try {
        let response = await fetch(url, requestOptions);
        if (response.ok) {
          const jsonResponse = await response.text();
          return response || true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    async getMedia({ commit }, { end, data }) {
      let query_string = toQueryString(data);
      let url = `${API_BASE}${end}?${query_string}`;
      const header = getLoggedInHeader(localStorage.getItem("token"));

      var requestOptions = {
        method: "GET",
        headers: header,
        redirect: "follow",
      };

      try {
        let response = await fetch(url, requestOptions);
        if (response.ok) {
          const jsonResponse = await response.text();
          return response || true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
    purgeAll({ commit }) {
      commit("LOG_OUT");
      localStorage.removeItem("vuex");
      localStorage.removeItem("token");
      window.location.reload();
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
